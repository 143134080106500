import React from "react"
import ArrowTop from "../../images/icons/arrow-top.svg"

type Params = {
  show: boolean
  position: {
    x: number
    y: number
  }
}

const FancyCursor: React.FC<Params> = ({ show, position }) => {
  const bgColor = show ? "#d7ff64" : "transparent"
  const size = show ? "100px" : ""
  const opacity = show ? "1" : "0"
  const transform = `translate(${position.x}px, ${position.y}px)`

  return (
    <div
      id="fancy-cursor"
      style={{
        transform: transform,
        backgroundColor: bgColor,
        width: size,
        height: size,
        opacity: opacity,
      }}
      className="fancy-cursor"
    >
      <ArrowTop />
    </div>
  )
}

export default FancyCursor
