import React, { useState } from "react"
import { Container, Col, Row } from "reactstrap"

import { ICardData } from "../../../types"
import FancyItem from "../FancyItem"

import FancyCursor from "../FancyCursor"

type Data = {
  items: ICardData[]
}

const FancyList: React.FC<Data> = ({ items }) => {
  const [cursorShown, setCursorShown] = useState(false)
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 })

  const easing = 8
  const handleCursorPosition = (e: React.MouseEvent<Element, MouseEvent>) => {
    setCursorPosition({
      x: (cursorPosition.x += (e.clientX - cursorPosition.x) / easing),
      y: (cursorPosition.y += (e.clientY - cursorPosition.y) / easing),
    })
  }
  return (
    <Container className="fancy-list-container">
      <Row
        onMouseMove={(e) => handleCursorPosition(e)}
        onMouseEnter={() => setCursorShown(true)}
        onMouseLeave={() => setCursorShown(false)}
      >
        <FancyCursor show={cursorShown} position={cursorPosition} />
        {items?.map((item, index) => (
          <FancyItem {...item} url={item.url} key={item.id} index={index} />
        ))}
      </Row>
    </Container>
  )
}

export default FancyList
