import React, { useState } from "react"
import { Link } from "gatsby"

import { Row, Col } from "reactstrap"

import ArrowTop from "../../images/icons/arrow-top.svg"
import { ICardData } from "../../../types"

const FancyItem: React.FC<ICardData> = ({ id, name, sup, desc, url }) => {
  return (
    <Col md={12} className="fancy-item">
      <Link to={url}>
        <Row>
          <Col md={6}>
            <small>(0{id}) </small>
            <h3>
              {name}
              {sup ? <sup>{sup}</sup> : ""}
            </h3>
          </Col>
          <Col md={6}>
            <h3>{desc}</h3>
            <ArrowTop className="icon fancy-item-icon" />
          </Col>
        </Row>
      </Link>
    </Col>
  )
}

export default FancyItem
