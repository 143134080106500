import React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"

import ArrowTop from "../../images/icons/arrow-top.svg"

const AnimatedPageHeader: React.FC = () => {
  return (
    <>
      {/* <motion.div animate={{ x: 100 }}>HAHSDASDSA</motion.div>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{
          opacity: 1,
          y: 0,
          transition: { delay: 1.2 },
        }}
        className="details"
      >
        <div className="location">
          <span>28.538336</span>
          <span>-81.379234</span>
        </div>
        <div className="mua">MUA: @mylifeascrystall</div>
      </motion.div> */}
      <motion.h1
        className="small"
        initial={{ opacity: 0, lineHeight: 1.8 }}
        animate={{
          opacity: 1,
          lineHeight: 1.2,
        }}
        transition={{ ease: "easeOut", duration: 1 }}
      >
        Your partner for top notch <span className="yellow-tag">mobile</span>{" "}
        and <span className="yellow-tag">web</span> solutions with
        human-centered approach.{" "}
      </motion.h1>
    </>
  )
}

export default AnimatedPageHeader
