import * as React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/Layout"
import CTA from "../components/CTA"
import WipeScreen from "../components/WipeScreen"
import SEO from "../components/SEO"
import TrustBuilder from "../components/TrustBuilder"

import { Container, Row, Col } from "reactstrap"

import PortfolioData from "../store/portfolio.json"

import { ICardData } from "./../../types"
import FancyList from "../components/FancyList"
import ArrowTop from "../images/icons/arrow-top.svg"

import ProgressiveImage from "../components/ProgressiveImage"
import AnimatedPageHeader from "../components/AnimatedPageHeader"

type DataProps = {
  site: {
    buildTime: string
    siteMetadata: {
      title: string
    }
  }
}

const IndexPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const Items: ICardData[] = PortfolioData

  return (
    <>
      <SEO
        title="Boutique Product Design Studio - Mobile Apps - Web Apps - UX/UI"
        slug="/"
      />
      <Row
        className="white-container case-study"
        style={{ backgroundColor: "transparent" }}
      >
        <Link
          to="https://calendar.app.google/C7kSm9catLxLyJXv5"
          target="_blank"
          className="animated-button book-call"
        >
          <div className="text-top-layer">book a call</div>
          <div className="text-bottom-layer">book a call</div>
          <div className="icon-container">
            <ArrowTop className="icon-top-layer" />
            <ArrowTop className="icon-bottom-layer" />
          </div>
        </Link>
        <Container className="wide">
          <Row className="mt-5 mb-5 no-gutters">
            <Col sm="10" className="super-margin">
              <AnimatedPageHeader />
            </Col>
          </Row>
          <TrustBuilder />
        </Container>
      </Row>
      <Row className="yellow-container" tag="section">
        <Container>
          <Row className="mt-5 no-gutters">
            <Col sm="6" className="mt-5">
              <h2 className="large">Turning Ideas Into Digital Solutions</h2>
            </Col>
            <Row className="no-gutters mt-5 mb-5">
              <Col sm="6">
                <div className="designer">
                  <h4>
                    Scope is a boutique product design studio, founded by Pawel
                    Malenczak - an award winning digital product designer,
                    software developer and AI enthusiast.
                  </h4>
                </div>
              </Col>
              <Col xs="12" sm="6" className="mb-5">
                <p>
                  Pawel's career journey has been marked by leadership in the
                  design and development of software, web, and mobile solutions.
                </p>
                <p>
                  His unique background in UI/UX design, software development,
                  and data science provides him with a distinctive perspective,
                  allowing him to strike a harmonious balance between innovation
                  and practicality. This, in turn, empowers companies to craft
                  successful, user-centric solutions.
                </p>
                <p>
                  Scope is more than just a studio; it's your strategic partner
                  providing expertise in digital product design, mobile or web
                  development. Whether by reinforcing your existing team or
                  assembling a dedicated crew of software developers, and taking
                  the lead in guiding the entire product development process.
                </p>
                <br />
                {/* <Link
                  to="https://zapier.com/how-it-works"
                  className="read-more"
                >
                  Read Full Bio
                  <ArrowTop className="icon" />
                </Link> */}
                {/* <Link
                  to="https://www.linkedin.com/in/pawelmalenczak/"
                  target="_blank"
                  className="read-more linkedin"
                >
                  View LinkedIn
                  <ArrowTop className="icon" />
                </Link> */}
                <Link
                  to="https://www.linkedin.com/in/pawelmalenczak/"
                  target="_blank"
                  className="animated-button"
                >
                  <div className="text-top-layer">View LinkedIn</div>
                  <div className="text-bottom-layer">View LinkedIn</div>
                  <div className="icon-container">
                    <ArrowTop className="icon-top-layer" />
                    <ArrowTop className="icon-bottom-layer" />
                  </div>
                </Link>
              </Col>
            </Row>
          </Row>
        </Container>
      </Row>
      <Row className="gray-container  pt-5" tag="section">
        <div className="marquee-container large mt-5">
          <div className="marquee black alternate">
            <span className="transparent-200">
              SERVICES * SERVICES * SERVICES *{" "}
            </span>
          </div>
          <div className="marquee black alternate">
            <span className="transparent-200">
              &nbsp;SERVICES * SERVICES * SERVICES *{" "}
            </span>
          </div>
        </div>
        <Container className="mt-5 pt-5">
          {/* <Row className="mt-5 no-gutters">
            <Col sm="12" className="super-margin center">
              <h2>Expertise & Services</h2>
            </Col>
          </Row> */}
          <Row className="mt-5 mb-5">
            <Col sm={{ size: 12 }}>
              <Row className="nowrap-mobile services-list">
                <Col xs="10" sm={{ size: 5 }} className="square no-gutters">
                  <span className="do-big">01.</span>
                  <div>
                    <h4>Product Design</h4>
                    <p>
                      Product Discovery
                      <br />
                      User Journeys
                      <br />
                      UI/UX Design
                      <br />
                      System Architecture
                      <br />
                      Product Roadmap
                    </p>
                  </div>
                </Col>
                <Col xs="10" sm={{ size: 5 }} className="square no-gutters">
                  <span className="do-big">02.</span>
                  <div>
                    <h4>UX/UI Strategy Consulting</h4>
                    <p>
                      UX Audits
                      <br />
                      Rapid Prototyping
                      <br />
                      Scalable Solutions
                      <br />
                      Customer-Centric Concepts
                    </p>
                  </div>
                </Col>
                <Col xs="10" sm={{ size: 5 }} className="square">
                  <span className="do-big">03.</span>
                  <div>
                    <h4>Agile Team Support</h4>
                    <p>
                      IT Project Management
                      <br />
                      Agile and Scrum Consulting
                      <br />
                      Proxy Product Owner Services
                    </p>
                  </div>
                </Col>
                <Col xs="10" sm={{ size: 5 }} className="square">
                  <span className="do-big">04.</span>
                  <div>
                    <h4>Prototypes and MVPs</h4>
                    <p>
                      Rapid Prototyping
                      <br />
                      Concept Validation
                      <br />
                      AI-Powered Solutions
                      <br />
                      Full-Scale Product Development
                    </p>
                  </div>
                </Col>
                <Col xs="10" sm={{ size: 5 }} className="square">
                  <span className="do-big">05.</span>
                  <div>
                    <h4>Web Apps</h4>
                    <p>
                      Scalable Web Solutions
                      <br />
                      UI/UX Design
                      <br />
                      Backend Development
                      <br />
                      Frontend Development
                    </p>
                  </div>
                </Col>
                {/* <Col
                  xs="10"
                  sm={{ size: 6 }}
                  className="square"
                  style={{ backgroundColor: "#fff" }}
                >
                  <h1>5</h1>
                  <div>
                    <h4>AI-powered solutions</h4>
                    <p>
                      Leveraging software capabilities with robust ready-to-go{" "}
                      <span className="highlighted">
                        artificial intelligence
                      </span>{" "}
                      solutions.
                    </p>
                  </div>
                </Col> */}
                <Col xs="10" sm={{ size: 5 }} className="square">
                  <span className="do-big">06.</span>
                  <div>
                    <h4>Mobile Apps</h4>
                    <p>
                      Mobile Experience Design
                      <br />
                      React Native Development
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>

            <Container className="fact-box">
              <Row align="center" className="mt-5 mb-5">
                <Col xs="6" sm="4">
                  <h2>+12 yrs</h2>
                  <p>experience</p>
                </Col>
                <Col xs="6" sm="4">
                  <h2>+50</h2>
                  <p>projects</p>
                </Col>
                <Col sm="4">
                  <h2>120</h2>
                  <p>Happy customers</p>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
      </Row>
      <Row
        className="white-container centered"
        tag="section"
        // style={{ backgroundColor: "#e7dfdc", color: "#000" }}
      >
        <div className="marquee-container large">
          <div className="marquee">
            <span className="transparent-200" style={{ color: "#000" }}>
              WORK * WORK * WORK *{" "}
            </span>
          </div>
          <div className="marquee">
            <span className="transparent-200 " style={{ color: "#000" }}>
              &nbsp;WORK * WORK * WORK *{" "}
            </span>
          </div>
        </div>

        <FancyList items={Items} />
      </Row>
      {/* <Row className="purple-container">
        <Container>
          <Row className="mb-5 mt-5">
            <Col sm="8" className="super-margin">
              <h2>AI Lab</h2>
              <p>
                Through my career, I've led the design and development of
                software, web and mobile solutions. With AI becomming more
                common aspect of the industry, I support the thoughtful
                application of AI to enhance, not replace, the human element.
              </p>
              https://timestwo.design/services
              https://southleft.com/about/
            </Col>
          </Row>
          <Row className="mb-3 flex-nowrap ">
            <Col sm={{ size: 3 }} className="square tall no-gutters">
              <div className="square-overlay">
                <h1>01</h1>
                <h2>Project A</h2>
                <span>Description</span>
              </div>
              <ProgressiveImage src="art4.jpg" align="center" />
            </Col>
            <Col sm={{ size: 4 }} className="square tall">
              <div className="square-overlay">
                <h1>01</h1>
                <h2>Project A</h2>
                <span>Description</span>
              </div>
              <ProgressiveImage src="art3.jpg" />
            </Col>
            <Col sm={{ size: 4 }} className="square tall">
              <div className="square-overlay">
                <h1>01</h1>
                <h2>Project A</h2>
                <span>Description</span>
              </div>
              <ProgressiveImage src="art1.jpg" />
            </Col>
            <Col sm={{ size: 4 }} className="square tall">
              <div className="square-overlay">
                <h1>01</h1>
                <h2>Project A</h2>
                <span>Description</span>
              </div>
              <ProgressiveImage src="art2.jpg" />
            </Col>
          </Row>
          <Row className="carousel-nav">
            <Col sm="6">
              <ul className="dotnav">
                <li>&nbsp;</li>
                <li>&nbsp;</li>
                <li className="active">&nbsp;</li>
              </ul>
            </Col>
            <Col sm="6">
              <ul className="paddlenav">
                <li>
                  <Button>←</Button>
                </li>
                <li>
                  <Button>→</Button>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Row> */}
      <Row className="yellow-container awards-section">
        <Container>
          <Row className="mt-5 no-gutters">
            <Col sm="12" className="super-margin center">
              <h2>International Awards & Recognition</h2>
            </Col>
          </Row>
          <Row className="mb-5 awards-list nowrap-mobile">
            <Col xs="10" sm={{ size: 3 }} className="square static">
              <div>
                <h3>01</h3>
                <h4>A'Design Award</h4>
                <span>Gold Winner / Milan, Italy</span>
              </div>
              <ProgressiveImage src="adesign.png" />
            </Col>
            <Col xs="10" sm={{ size: 3 }} className="square static">
              <div>
                <h3>02</h3>
                <h4>Behance</h4>
                <span>Featured in Curated Galleries</span>
              </div>
              <ProgressiveImage src="behance.png" />
            </Col>
            <Col xs="10" sm={{ size: 3 }} className="square static">
              <div>
                <h3>03</h3>
                <h4>Awwwards</h4>
                <span>Honorable mention</span>
              </div>
              <ProgressiveImage src="aww.png" />
            </Col>
          </Row>
        </Container>
      </Row>
      <CTA />
      <WipeScreen />
    </>
  )
}

IndexPage.Layout = Layout
export default IndexPage

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
      siteMetadata {
        title
      }
    }
  }
`
