import React from "react"

import { Container, Col } from "reactstrap"

import ProgressiveImage from "../ProgressiveImage"

const TrustBuilder = () => {
  const logoListOne = () => {
    return (
      <ul className="logo-list">
        <Col tag="li" sm="2" xs="6" className="logo-box">
          <ProgressiveImage src="clients/zyagnum.png" />
        </Col>
        <Col tag="li" sm="2" xs="6" className="logo-box">
          <ProgressiveImage src="clients/brandify.png" />
        </Col>
        <Col tag="li" sm="2" xs="6" className="logo-box">
          <ProgressiveImage src="clients/involved.png" />
        </Col>
        <Col tag="li" sm="2" xs="6" className="logo-box">
          <ProgressiveImage src="clients/pmg.png" />
        </Col>
        <Col tag="li" sm="2" xs="6" className="logo-box">
          <ProgressiveImage src="clients/biologica.png" />
        </Col>
        <Col tag="li" sm="2" xs="6" className="logo-box">
          <ProgressiveImage src="clients/gpr.png" />
        </Col>
      </ul>
    )
  }
  const logoListTwo = () => {
    return (
      <ul className="logo-list">
        <Col tag="li" sm="2" xs="6" className="logo-box">
          <ProgressiveImage src="clients/shelly.png" />
        </Col>
        <Col tag="li" sm="2" xs="6" className="logo-box">
          <ProgressiveImage src="clients/samedica.png" />
        </Col>
        <Col tag="li" sm="2" xs="6" className="logo-box">
          <ProgressiveImage src="clients/nio.png" />
        </Col>
        <Col tag="li" sm="2" xs="6" className="logo-box">
          <ProgressiveImage src="clients/udeserve.png" />
        </Col>
        <Col tag="li" sm="2" xs="6" className="logo-box">
          <ProgressiveImage src="clients/foodsensor.png" />
        </Col>
        <Col tag="li" sm="2" xs="6" className=" logo-box">
          <ProgressiveImage src="clients/oneplanet.png" />
        </Col>
      </ul>
    )
  }
  return (
    <Container className="trust-builder wide">
      <div className="marquee-container mb-3">
        <div className="marquee">{logoListOne()}</div>
        <div className="d-none d-sm-block marquee">{logoListOne()}</div>
      </div>
      <div className="marquee-container">
        <div className="marquee slow">{logoListTwo()}</div>
        <div className="d-none d-sm-block marquee slow">{logoListTwo()}</div>
      </div>
    </Container>
  )
}

export default TrustBuilder
